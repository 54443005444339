import { memo, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'

import styles from './sheet.module.css'

const useSheet = () => {
  const [sheetRoot, setSheetRoot] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let root = document.querySelector('#sheet-root')
    if (!root) {
      root = document.createElement('div')
      root.id = 'sheet-root'
      document.body.appendChild(root)
    }
    setSheetRoot(root as HTMLDivElement)
    return () => {
      if (document.body.contains(root)) {
        document.body.removeChild(root)
      }
    }
  }, [])

  const Sheet = memo(
    function Sheet({
      children,
      show,
      onClose,
    }: {
      children: React.ReactNode
      show: boolean
      onClose: () => void
    }) {
      useEffect(() => {
        if (!sheetRoot) return

        const sheetId = Date.now()
        const sheetElement = document.createElement('div')
        sheetElement.id = `sheet-${sheetId}`
        sheetElement.className = `${styles.sheet} sheet fixed left-0 top-0 w-full h-full z-50 flex justify-end items-start`
        sheetElement.addEventListener('click', (e) => {
          e.stopPropagation()
          e.preventDefault()
          if (e.currentTarget === e.target) {
            onClose()
          }
        })
        let root: ReactDOM.Root | null = null

        const removesheet = () => {
          setTimeout(() => {
            root && root.unmount()
            if (sheetRoot.contains(sheetElement)) {
              sheetRoot.removeChild(sheetElement)

              document.body.style.removeProperty('overflow')
              document.body.style.removeProperty('touch-action')
              // document.body.style.removeProperty('padding-right')
              // document.body.style.removeProperty('pointer-events')
            }
          }, 0)
        }

        if (show) {
          sheetRoot.appendChild(sheetElement)
          root = ReactDOM.createRoot(sheetElement)

          setTimeout(() => {
            document.body.style.overflow = 'hidden'
            document.body.style.touchAction = 'none'
            // document.body.style.paddingRight = '15px'
            // document.body.style.pointerEvents = 'none'
          }, 100)

          root.render(
            <div
              className={`${styles.sheetInner} max-w-sm w-[90%] h-full pointer-events-auto`}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {children}
            </div>
          )
        } else {
          removesheet()
        }

        return () => {
          removesheet()
        }
      }, [show, sheetRoot, children, onClose])

      return null
    },
    (prevProps, nextProps) => {
      return prevProps.show === nextProps.show
    }
  )

  return {
    Sheet,
  }
}

export default useSheet
